import fetchJsonp from 'fetch-jsonp';
import { envConfig } from 'config';
import {
  HideState,
  ErrorState,
  ComplianceState,
  WidgetState,
  WidgetOverrideParams,
  DispatchEvents,
  JotType
} from 'consts';
import {
  evalNatures,
  getTopmostPageUrl,
  handleError,
  idsync,
  isNullOrUndef,
  widgetCompliance,
  getLocalBackupAlias,
  containsSelector,
  getConfigUrl,
  dispatchEvent,
  toObject,
  demographicsStorage,
} from 'utils';
import { jot } from 'actions/jot';
import CaptionStatic from 'consts/CaptionStatic';

const { widgetTargetEndpoint } = envConfig;

const jotTargetResolved = (oid, targetId, pin, pinMode, isMeta, state) => {
  jot({
    state,
    space: 'poll',
    type: JotType.Resolved,
    data: { otarget: oid, target: targetId, pin, pinMode, isMeta }
  });
};


export const getTarget = ({ store, state, targetId, instanceId }) => {
  const topmostPageUrl = getTopmostPageUrl(state.articleUrl);
  const urlContext = topmostPageUrl.replace(/^[^:]+:/, '')
    .replace(/\/+$/, '');
  const toState = {
    instanceId,
    config: {},
    complianceState: ComplianceState.Off,
    widgetState: WidgetState.TargetLoading,
    adEngageEnabled: false,
    gameEnabled: false,
    readOnly: false,
    urlContext,
    questionsData: {},
    submitIds: [],
    servedIds: [],
    staticCaptions: Object.assign([], CaptionStatic)
  };
  store.setState(toState);
  const { apiTimeoutMS } = envConfig;
  const { bootstrap, hostname } = state;
  const {
    e: editable,
    contextualQuestionHiddenState,
    contextualTargetHiddenState,
    csAlias,
    compliance
  } = bootstrap || {};

  const overrideNatures = (() => {
    const natures = topmostPageUrl.match(new RegExp(`[?&]${WidgetOverrideParams.Natures}=([^&]*)`));
    return isNullOrUndef(natures) ? null : decodeURIComponent(natures[1]).split(',');
  })();
  const readOnly = topmostPageUrl.includes(`${WidgetOverrideParams.Readonly}=1`);

  return fetchJsonp(
    getConfigUrl(state, targetId, instanceId, widgetTargetEndpoint, urlContext, topmostPageUrl, csAlias, compliance),
    { timeout: apiTimeoutMS })
    .then(res => res.json())
    .then(config => {
      let widgetState = WidgetState.TargetLoaded;
      const {
        natures,
        rootbeer,
        compliance,
        domBlacklist: blacklistSelector,
        oid,
        id: targetId,
        contextualQuestion: pin,
        pinMode,
        isMeta,
        d,
      } = toObject(config);


      //FIXME: DO NOT MERGE!
      //FIXME: Remove mocking code below when 8366 and 8373 are setup properly for 'question-reporting-enabled' and 'show-poll-by-cs-left'
      const mockCSAdNatures = (targetId === 8366 || targetId === 8373)
        ? ['question-reporting-enabled', 'show-poll-by-cs-left']: [];

      const naturesWithMockCSAdNatures = [...natures, ...mockCSAdNatures];

      demographicsStorage.mergeLocalStorageDemographics(d);
      // const updatedConfig = Object.assign({}, config,
      //   { natures: isNullOrUndef(overrideNatures) ? natures : overrideNatures });
      //FIXME: Remove mocking updatedConfig code below and replace with commented updatedConfig code above
      const updatedConfig = Object.assign({}, config,
        { natures: isNullOrUndef(overrideNatures) ? naturesWithMockCSAdNatures : overrideNatures });
      const { adEngageEnabled: adEngageEnabledNature, gameEnabled } = evalNatures(updatedConfig['natures']);
      const adEngageEnabled = adEngageEnabledNature && rootbeer && typeof rootbeer === 'object';
      const complianceCheckOn = widgetCompliance.complianceCheckOn(compliance);
      const complianceAccepted = widgetCompliance.complianceStorageOk(compliance);
      const complianceState = complianceCheckOn
        ? (complianceAccepted ? ComplianceState.Accepted : ComplianceState.Ask)
        : ComplianceState.Off;

      //eslint-disable-next-line
      console.log('boosh getTarget', { updatedConfig, targetId, mockCSAdNatures });

      if (complianceAccepted || !complianceCheckOn) {
        dispatchEvent(instanceId, this, DispatchEvents.IDSync, false, {
          hostname
        });
        idsync(updatedConfig, hostname);
      }

      // set backupAlias which is our localStorage fallback identifier for a user
      // we set this independent of compliance state
      const backupAlias = getLocalBackupAlias();

      if (containsSelector(document, blacklistSelector)) {
        //doc contains blacklist selector, hide for respondents, show for admins
        widgetState = editable ? WidgetState.BlacklistAdmin : WidgetState.Hidden;
      } else if (contextualQuestionHiddenState === HideState.Respondent) {
        //ghost widget
        widgetState = WidgetState.Hidden;
      } else if (contextualTargetHiddenState === HideState.Admin
        || contextualTargetHiddenState === HideState.AdminPosition) {
        widgetState = WidgetState.TargetAdmin;
      } else if (contextualQuestionHiddenState === HideState.Admin) {
        widgetState = WidgetState.QuestionAdmin;
      }

      //jot target loaded if we are a normal target, or a metatarget that has
      //resolved to another target id
      if (!isMeta || oid !== targetId) {
        // must use latest state
        jotTargetResolved(oid, targetId, pin, pinMode, isMeta, store.getState());
      }

      return {
        config: updatedConfig,
        urlContext,
        complianceState,
        widgetState,
        backupAlias,
        adEngageEnabled,
        gameEnabled,
        readOnly
      };
    }).catch((e) => handleError(ErrorState.Target, e));
};
